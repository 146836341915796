import { render, staticRenderFns } from "./CreateChildDoc.vue?vue&type=template&id=0707f9ab&scoped=true&"
import script from "./CreateChildDoc.vue?vue&type=script&lang=ts&"
export * from "./CreateChildDoc.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0707f9ab",
  null
  
)

export default component.exports