import { render, staticRenderFns } from "./RenameDocument.vue?vue&type=template&id=9707ce58&scoped=true&"
import script from "./RenameDocument.vue?vue&type=script&lang=ts&"
export * from "./RenameDocument.vue?vue&type=script&lang=ts&"
import style0 from "./RenameDocument.vue?vue&type=style&index=0&id=9707ce58&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9707ce58",
  null
  
)

export default component.exports