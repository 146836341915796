



























import { Component, Vue, Prop } from 'vue-property-decorator'
import DefaultModal from '@/components/modals/Default.vue'
import TextInput from '@/components/inputs/Text.vue'

@Component({
    components: { DefaultModal, TextInput }
})
export default class Modify extends Vue {
    @Prop()
    private document!: any

    @Prop()
    public open: boolean = false

    @Prop()
    public loading: boolean = false

    public name: any = null

    updated() {
        if (!this.document) this.name = null        
        if (this.name === null && !!this.document) this.name = this.document.title
    }

    closeModal() {
        this.name = null        
        this.$emit('closeModal')
    }

    saveTitle() {
        const doc = {...this.document}
        doc.title = this.name
        this.$emit('updateTitle', doc)
        this.name = null
    }

}
