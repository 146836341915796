













import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({})
export default class Modify extends Vue {
    @Prop()
    public modifyId: any

    @Prop()
    public document: any

    public setModifyId() {
        this.$emit('updateModify', this.document.uuid)
    }
    
    public setModifyIdToNull() {
        if (!this.modifyId || this.modifyId !== this.document.uuid) return;
        this.$emit('updateModify', null)
    }

    public setDocumentToRename() {
        this.$emit('renameDocument')
    }

    private toggleArchive() {
        this.$emit('toggleArchive', this.document)
    }

    private openCreateChildModal() {
        this.$emit('openCreateModal', this.document)
    }
}
