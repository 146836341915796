
import { Component, Vue, Prop } from 'vue-property-decorator'
import DefaultModal from '@/components/modals/Default.vue'
import TextInput from '@/components/inputs/Text.vue'

@Component({
    components: { DefaultModal, TextInput }
})
export default class CreateChildDoc extends Vue {
    @Prop()
    open: boolean = false

    @Prop()
    document: any

    @Prop()
    loading: boolean = false

    private name: string = ''

    closeModal() {
        this.name = ''
        this.$emit('close')
    }

    saveTitle() {
        this.$emit('save', { document: this.document, name: this.name })
    }
}
